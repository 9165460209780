import {onDomReady} from "../../components/dynamic/observer";
import {Navigation, Swiper} from 'swiper';
import '../../../node_modules/swiper/swiper.scss';

function init() {
    const container = document.querySelector('[data-gallery-slider-container]');

    if (container) {
        const slider = new Swiper(document.querySelector('[data-gallery-slider]'), {
            modules: [Navigation],
            slidesPerView: "auto",
            breakpoints: {
                0: {
                    spaceBetween: 12,
                },
                768: {
                    spaceBetween: 14,
                },
                1200: {
                    spaceBetween: 22,
                },
            },
        });

        const setCursorMove = (slider) => {
            slider.on('touchMove', (slider, e) => {
                const event = new CustomEvent('cursorMove', {
                    detail: {
                        offsetX: e.x,
                        offsetY: e.y,
                    }
                });
                window.dispatchEvent(event);
            });
        }

        setCursorMove(slider);
    }
}

onDomReady(init);