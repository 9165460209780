import {onDomReady} from "../../components/dynamic/observer";
import {Navigation, Swiper, Pagination} from 'swiper';
import '../../../node_modules/swiper/swiper.scss';

Swiper.use([Pagination]);

window.addEventListener('load', () => {
    init();
})

function init()
{
    const container = document.querySelector('[data-advantages-slider-container]');

    if (window.innerWidth > 1199 || window.innerWidth < 768) {
        if (container) {
            const leftArrow = document.querySelector('[data-advantages-slider-left]');
            const rightArrow = document.querySelector('[data-advantages-slider-right]');

            const slider = new Swiper(document.querySelector('[data-advantages-slider]'), {
                modules: [Navigation],
                slidesPerView: "auto",
                freeMode: false,
                breakpoints: {
                    0: {
                        slidesPerView: 1.1,
                        spaceBetween: 14,
                        pagination: {
                            el: document.querySelector('[data-advantages-slider-pagination]'),
                            type: 'bullets',
                            clickable: true,
                        },
                        navigation: {
                            prevEl: leftArrow,
                            nextEl: rightArrow,
                        },
                    },
                    786: {
                        slidesPerView: 2,
                        spaceBetween: 22,
                    },
                    1200: {
                        slidesPerView: 3,
                        spaceBetween: 22,
                    },
                },
            });
        }
    }
}

