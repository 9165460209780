import {onDomReady} from "../../components/dynamic/observer";
import {Navigation, Pagination, Swiper} from 'swiper';
import '../../../node_modules/swiper/swiper.scss';

Swiper.use([Pagination]);

function init() {
    const leftArrow = document.querySelector('[data-service-slider-left]');
    const rightArrow = document.querySelector('[data-service-slider-right]');

    const slider = new Swiper(document.querySelector('[data-service-slider]'), {
        modules: [Navigation],
        pagination: {
            el: document.querySelector('[data-service-slider-pagination]'),
            type: 'bullets',
            clickable: true,
        },
        breakpoints: {
            0: {
                slidesPerView: 1,
                spaceBetween: 14,
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 22,
            },
            1200: {
                slidesPerView: 3,
                spaceBetween: 22,
            }
        },
        navigation: {
            prevEl: leftArrow,
            nextEl: rightArrow,
        },
    });
}

onDomReady(init);