const tabsList = document.querySelector('[data-event-tabs]');
const itemsList = document.querySelector('[data-events-list]');

if (tabsList && itemsList) {
    const links = tabsList.querySelectorAll('[data-tab-link]');
    const list = itemsList.querySelectorAll('[data-event-item]');

    const allItems = tabsList.querySelector('[data-tab-link-all]');
    const newItems = tabsList.querySelector('[data-tab-link-new]');
    const oldItems = tabsList.querySelector('[data-tab-link-old]');

    allItems.addEventListener('click', () => {
        list.forEach((item) => {
            item.classList.remove('_invisible');
        })
    });

    newItems.addEventListener('click', () => {
        list.forEach((item) => {
            if (item.classList.contains('_old')) {
                item.classList.add('_invisible');
            }
            if (item.classList.contains('_new')) {
                item.classList.remove('_invisible');
            }
        })
    });

    oldItems.addEventListener('click', () => {

        list.forEach((item) => {
            if (item.classList.contains('_old')) {
                item.classList.remove('_invisible');
            }
            if (item.classList.contains('_new')) {
                item.classList.add('_invisible');
            }
        })
    });

    links.forEach((link) => {
        link.addEventListener('click', () => {
            links.forEach((link) => {
                link.parentNode.classList.remove('_active');
            });
            link.parentNode.classList.add('_active');
        });
    });
}