const supportsVideo = !!document.createElement('video').canPlayType;

if (supportsVideo) {
    const videoContainer = document.querySelector('[data-video-container]');
    const video = document.querySelector('[data-video]');
    const videoControls = document.querySelector('[data-video-controls]');

    if (videoContainer) {
        // Hide the default controls
        video.controls = false;

        // Display the user defined video controls
        videoControls.style.display = 'block';

        const playpause = document.querySelector('[data-play-pause]');
        const playFullScreen = document.querySelector('[data-play-full-screen]');

        if (playFullScreen) {
            playFullScreen.addEventListener('click', (e) => {

                if (video.paused) {
                    if (video.requestFullscreen) {
                        video.requestFullscreen();
                    } else if (video.webkitRequestFullscreen) { /* Safari */
                        video.webkitRequestFullscreen();
                    } else if (video.msRequestFullscreen) { /* IE11 */
                        video.msRequestFullscreen();
                    }
                }

                playPauseVideo();
            });
        }

        if (playpause) {
            playpause.addEventListener('click', (e) => {
                playPauseVideo();
            });
        }

        video.addEventListener('playing', (e) => {
            let button = playpause ?? playFullScreen;
            button.classList.add('_hidden')

            video.addEventListener('click', (e) => {
                video.pause();
            })
        });

        video.addEventListener('pause', (e) => {
            let button = playpause ?? playFullScreen;
            if (button.classList.contains('_hidden')) {
                button.classList.remove('_hidden')
            }
        });

        function playPauseVideo() {
            if (video.paused || video.ended) {
                video.play();
            } else {
                video.pause();
            }
        }
    }
}