import MiniMasonry from "./minimasonry";
import {onDomReady} from "../../components/dynamic/observer";

window.addEventListener('load', () => {
    const container = document.querySelector('.masonry_transition');

    setTimeout(() => {
        if (container) {
            let baseWidth = 230;
            let gutter = 22; //grid-gap
            let gridSpacer = 0;
            let columnsNumber = 12;
            let rowWidth = 1600;
            let gridIndent = 0;

            //да-да, сейчас будет пересчет размеров как для ксс, иначе оно достаточно сильно не попадает в макет
            //см grid.css и settings.css

            if (window.innerWidth < 1200) {
                gutter = 14;
            }

            //large
            if (window.innerWidth > 1599) {
                gridSpacer = 120;
                gridIndent = (window.innerWidth - rowWidth) / 2;
            }

            //s-large
            if (window.innerWidth > 1199 && window.innerWidth < 1600) {
                gridSpacer = 120;
            }

            //medium
            if (window.innerWidth > 767 && window.innerWidth < 1200) {
                gridSpacer = 34;
                columnsNumber = 6;
            }

            //small
            if (window.innerWidth < 768) {
                gridSpacer = 18;
                columnsNumber = 4;
            }

            let columnWidth = (window.innerWidth - 2 * (gridIndent + gridSpacer) - ((columnsNumber - 1) * gutter)) / columnsNumber;

            if (window.innerWidth > 1200) {
                baseWidth = columnWidth * 3 + gutter * 2;
            } else {
                baseWidth = columnWidth * 2 + gutter;
            }

            const masonry = new MiniMasonry({
                container: container,
                baseWidth: baseWidth,
                gutter: gutter,
                surroundingGutter: false
            });

            setTimeout(() => {
                document.querySelectorAll('.masonry_transition > .masonry_transition__element').forEach((el) => {
                    el.classList.add('_visible');
                })
            }, 32);
        }
    }, 32);

});
