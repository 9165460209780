require('./about-slider');
require('./event-slider');
require('./service-slider');
require('./article-slider');
require('./another-services-slider');
require('./another-events-slider');
require('./closest-events-slider');
require('./index-slider');
require('./team-slider');
require('./partners-slider');
require('./gallery-slider');
require('./advantages-slider');
require('./index-rslider');
