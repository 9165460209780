import LiveEvent from "../../components/live/live";
import Modal from "../../components/modal/modal";

/**
 *
 *  Локальные модалочки
 *
 */

new LiveEvent('click', '[data-modal]', function openModal(e) {
    e.preventDefault();
    let link = this;
    let modal = new Modal(this, {
        theme: 'default',
        animation: {
            classIn: '_open',
            classOut: '_close',
            timeoutIn: 16,
            timeoutOut: 16,
        },
        closerText: '',
        onAfterOpen: function () {
        },
        onBeforeClose: function () {
        },
        onFormSuccess: function () {
            const submitEvent = new Event('modal-form:success');
            submitEvent.element = link;
            document.dispatchEvent(submitEvent);
        },
    });
    return false;
});

/**
 *
 *  Masonry
 *
 */
// const masonry = new MiniMasonry({
//     container: '.masonry_transition',
//     baseWidth: '315',
//     gutter: 22,
//     surroundingGutter: false
// });