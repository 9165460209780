import RSlider from "../../components/rslider/rslider";
import {onDomReady} from "../../components/dynamic/observer";

onDomReady(() => {
    const button = document.querySelector('[data-hide-button]');
    const sliderElement = document.querySelector('[data-rslider="index"]');

    if (sliderElement) {
        const slider = new RSlider(
            sliderElement,
            {
                onStart: ({ slideIn, slideFriendsIn }) => {
                    slideFriendsIn.forEach((friendIn) => {
                        friendIn.classList.add('_active');
                    })
                    slideIn.classList.add('_active');
                },
                timeline: [
                    RSlider.action({
                        // К слайдам докидывается:
                        // К появляющемуся: --rslider-first-act-in-progress: 0.2;
                        // К исчезающему: --rslider-first-act-out-progress: 0.2;
                        // easing ниже настраивается

                        name: 'first-act',
                        duration: 900,
                        delay: 0,
                        // easeInOutCubic прогресс для "приходящего" слайда
                        easingIn: (x) => x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2,
                        // linear прогресс для "уходящего" слайда
                        easingOut: (x) => x,
                        onStart: ({ slideIn, slideOut, slideFriendsIn, slideFriendsOut }) => {
                            slideIn.classList.add('_in-first-act');
                            slideOut.classList.add('_out-first-act');

                            slideFriendsIn.forEach((friendIn) => {
                                friendIn.classList.add('_in-first-act');
                                friendIn.classList.add('_active');
                            })

                            slideFriendsOut.forEach((friendOut) => {
                                friendOut.classList.add('_out-first-act');
                                friendOut.classList.remove('_active');
                            })

                            button.classList.add('_hidden')
                        },
                        onEnd: ({ slideIn, slideOut, slideFriendsIn, slideFriendsOut }) => {
                            slideIn.classList.remove('_in-first-act');
                            slideOut.classList.remove('_out-first-act');

                            slideFriendsIn.forEach((friendIn) => {
                                friendIn.classList.remove('_in-first-act');

                            })

                            slideFriendsOut.forEach((friendOut) => {
                                friendOut.classList.remove('_out-first-act');

                            })

                            slideOut.classList.remove('_active');
                            slideIn.classList.add('_active');
                            button.classList.remove('_hidden');
                        }
                    }),
                ]
            }
        );

        const nextSlideElement = document.querySelector('[data-next-slide]');
        nextSlideElement.addEventListener('click', (e) => {
            e.preventDefault();
            slider.next();
        })

        const prevSlideElement = document.querySelector('[data-prev-slide]');
        prevSlideElement.addEventListener('click', (e) => {
            e.preventDefault();
            slider.prev();
        })

        const paginations = document.querySelectorAll('[data-rslider-pagination]');
        paginations.forEach((pagination, index) => {
            pagination.addEventListener('click', (e) => {
                e.preventDefault()
                slider.toSlide(index);
            })
        });
    }
})