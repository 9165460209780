import {onDomReady} from "../../components/dynamic/observer";
import {Autoplay, EffectFade, Navigation, Pagination, Swiper} from 'swiper';
import '../../../node_modules/swiper/swiper.scss';

Swiper.use([Pagination]);

function init() {
    const leftArrow = document.querySelector('[data-index-slider-left]');
    const rightArrow = document.querySelector('[data-index-slider-right]');

    const slider = new Swiper(document.querySelector('[data-index-slider]'), {
        modules: [Navigation, EffectFade, Autoplay],
        slidesPerView: "auto",
        effect: 'fade',
        // autoplay: {
        //     delay: 2500,
        //     disableOnInteraction: true,
        //     stopOnLastSlide: true,
        // },
        navigation: {
            prevEl: leftArrow,
            nextEl: rightArrow,
        },
        pagination: {
            el: document.querySelector('[data-index-slider-pagination]'),
            type: 'bullets',
            clickable: true,
        }
    });
}

onDomReady(init);