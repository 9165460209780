import {onDomReady} from "../../components/dynamic/observer";
import {Navigation, Swiper} from 'swiper';
import '../../../node_modules/swiper/swiper.scss';

function init() {
    const leftArrow = document.querySelector('[data-team-slider-left]');
    const rightArrow = document.querySelector('[data-team-slider-right]');

    const slider = new Swiper(document.querySelector('[data-team-slider]'), {
        modules: [Navigation],
        breakpoints: {
            0: {
                slidesPerView: 1.3,
                spaceBetween: 14,
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 14,
            },
            1200: {
                slidesPerView: 4,
                spaceBetween: 22,
            },
        },
        navigation: {
            prevEl: leftArrow,
            nextEl: rightArrow,
        },
    });
}

onDomReady(init);