import {onDomReady} from "../../components/dynamic/observer";
import {Navigation, Swiper, Pagination} from 'swiper';
import '../../../node_modules/swiper/swiper.scss';
import {ScrollSlider} from "./scrollSlider/ScrollSlider";

Swiper.use([Pagination]);

window.addEventListener('load', () => {
    init();
})

function init() {
    if (window.innerWidth < 768) {
        const slider = new Swiper(document.querySelector('[data-closest-event-slider]'), {
            modules: [Navigation],
            slidesPerView: "auto",
            freeMode: false,
            pagination: {
                el: document.querySelector('[data-closest-event-slider-pagination]'),
                type: 'bullets',
                clickable: true,
            }
        });
    }
}

