import {onDomReady} from "../../components/dynamic/observer";
import {Navigation, Swiper, Pagination} from 'swiper';
import '../../../node_modules/swiper/swiper.scss';

Swiper.use([Pagination]);

function init() {
    const slider = new Swiper(document.querySelector('[data-another-event-slider]'), {
        modules: [Navigation],
        slidesPerView: "auto",
        freeMode: false,
        pagination: {
            el: document.querySelector('[data-another-event-slider-pagination]'),
            type: 'bullets',
            clickable: true,
        },
        breakpoints: {
            0: {
                slidesPerView: 1,
                spaceBetween: 14,
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 22,
            },
            1200: {
                slidesPerView: 3,
                spaceBetween: 22,
            }
        },
    });
}

onDomReady(init);