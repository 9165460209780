import {onDomReady} from "../../components/dynamic/observer";
import {Navigation, Swiper} from 'swiper';
import '../../../node_modules/swiper/swiper.scss';

function init() {
    const leftArrow = document.querySelector('[data-event-slider-left]');
    const rightArrow = document.querySelector('[data-event-slider-right]');

    const slider = new Swiper(document.querySelector('[data-event-slider]'), {
        modules: [Navigation],
        slidesPerView: "auto",
        navigation: {
            prevEl: leftArrow,
            nextEl: rightArrow,
        },
    });
}

onDomReady(init);